/* eslint-disable */
const setupWebViewJavascriptBridge = function() {};
export default {
  updateTitle(title) {
    //     a) 更新标题
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'updateTitle',
    //   title,
    // });
    return new Promise((resolve, reject) => {
      console.log('updateTitle');
      document.title = title;
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler(
          'updateTitle',
          {
            title,
          },
          (responseData) => {
            resolve(responseData);
            console.log('JS received response:', responseData);
          }
        );
      });
      // Jockey.send("getNetworkInfo", {}, (payload) => {
      //     // console.log('payload of getNetworkInfo: ');
      //     // console.log(payload);
      //     if (payload && payload.active) {
      //         resolve(payload);
      //     } else {
      //         reject('Not supported');
      //     }
      // });
    });
  },
  showLogin(params) {
    // g)  跳转到login
    // this.updateTitle('调用了showLogin');
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showLogin', params, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
  },
  updateLogo() {
    //     a) 更新标题
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'updateTitle',
    //   title,
    // });
    return new Promise((resolve, reject) => {
      console.log('updateLogo');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('updateLogo', {}, (responseData) => {
          resolve(responseData);
          console.log('JS received response:', responseData);
        });
      });
      // Jockey.send("getNetworkInfo", {}, (payload) => {
      //     // console.log('payload of getNetworkInfo: ');
      //     // console.log(payload);
      //     if (payload && payload.active) {
      //         resolve(payload);
      //     } else {
      //         reject('Not supported');
      //     }
      // });
    });
  },
  showBackAndClose() {
    //     a) 显示关闭按钮 和 左上角返回按钮
    this.showRightClose();
    this.showLeftBack();
  },
  showRightClose() {
    //    b) 显示关闭按钮
    return new Promise((resolve, reject) => {
      console.log('showRightClose');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showRightClose', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'showRightClose',
    // });
  },
  showLeftMenu() {
    // c) 显示左上角菜单
    return new Promise((resolve, reject) => {
      console.log('showLeftMenu');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showLeftMenu', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'showLeftMenu',
    // });
  },
  showLeftBack() {
    // d) 显示左上角返回按钮
    return new Promise((resolve, reject) => {
      console.log('showLeftBack');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showLeftBack', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'showLeftBack',
    // });
  },
  setUserData(data) {
    // e) 保存数据
    localStorage.setItem('userData', JSON.stringify(data));
    return new Promise((resolve, reject) => {
      console.log('setUserData');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('setUserData', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'setUserData',
    //   data, // data 为 {}
    // });
  },
  getUserData(callback) {
    // f) 获取用户数据
    return new Promise((resolve, reject) => {
      console.log('getUserData');
      resolve(JSON.parse(localStorage.getItem('userData')));
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getUserData', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'getUserData',
    //   callback,
    // });
    // callback("已保存用户数据JSON，或者是一个空的JSON对象字符串")
  },
  showOpenAccountEntry(data) {
    // e) 保存数据
    return new Promise((resolve, reject) => {
      console.log('showOpenAccountEntry');
      window.VueObj.$router.push('/entry');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showOpenAccountEntry', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'setUserData',
    //   data, // data 为 {}
    // });
  },
  getCookie(data) {
    // e) 保存数据
    return new Promise((resolve, reject) => {
      console.log('getCookie');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getCookie', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'setUserData',
    //   data, // data 为 {}
    // });
  },
  getUserInfo(callback) {
    // g)  获取用户uid
    // this.updateTitle('调用了getUserID');
    console.log('getUserInfo');
    resolve(false);
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getUserInfo', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'getUserID',
    //   callback,
    // });
    // callback('{"userID": 10001}')
  },
  getUserId(callback) {
    // g)  获取用户uid
    // this.updateTitle('调用了getUserID');
    return new Promise((resolve, reject) => {
      console.log('getUserId');
      resolve(false);
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getUserId', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'getUserID',
    //   callback,
    // });
    // callback('{"userID": 10001}')
  },
  refreshOpenAccountStatus(data) {
    // 开户退出刷新页面
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('refreshOpenAccountStatus', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
  },
  showWebView(url) {
    if (url.indexOf('?rd=') > -1) {
      // 获取rd后面的
      const arr = url.split('?rd=');
      const path = arr[1];
      window.VueObj.$router.push(path);
    } else {
      location.href = url;
    }
  },
  // 用于从二级页面跳转到一级页面的方法
  showOpenAccountHTML5() {
    console.log('showOpenAccountHTML5');
    window.VueObj.$router.push('/entry');
  },
  // 关闭webview
  closeWebView() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('closeWebViewContainer', {}, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
  //h5页面用户行为事件追踪
  trackEvent(event) {
    return new Promise((resolve, reject) => {
      //国内加载不了GA
      let sourceName = sessionStorage.getItem('qd');
      let sourceNameList = [
        'Planto',
        'Facebook',
        'DaWokGum',
        'LinkedIn',
        'Optimise',
        'Mailchimp',
      ];
      if (window.ga && sourceNameList.indexOf(sourceName) > -1) {
        ga('myTracker.send', {
          hitType: 'event',
          eventCategory: 'openAcc',
          eventAction:
            localStorage.getItem('uid') +
            '_' +
            'from' +
            '_' +
            sourceName +
            '_' +
            'Reach',
          eventLabel: event.event,
        });
      }
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('trackEvent', event, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
  // 忘记密码
  showForgetTradePasswordPage() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler(
          'showForgetTradePasswordPage',
          {},
          (responseData) => {
            console.log(
              'showForgetTradePasswordPage JS received response:',
              responseData
            );
            resolve(responseData);
          }
        );
      });
    });
  },
  // 打开原生页面
  showNativePage(params) {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showNativePage', params, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
  isLogin() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('isLogin', {}, (responseData) => {
          console.log(
            'showForgetTradePasswordPage JS received response:',
            responseData
          );
          resolve(responseData);
        });
      });
    });
  },
};
