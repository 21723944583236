// ios 接口

/* eslint-disable */

const setupWebViewJavascriptBridge = function setupWebViewJavascriptBridge(
  callback
) {
  if (typeof window !== 'undefined' && window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge);
  }
  if (typeof window !== 'undefined' && window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback);
  }
  if (typeof window !== 'undefined') window.WVJBCallbacks = [callback];
  const WVJBIframe = document.createElement('iframe');
  WVJBIframe.style.display = 'none';
  WVJBIframe.src = 'https://__bridge_loaded__';
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(() => {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
};

export default {
  updateTitle(title) {
    //     a) 更新标题
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'updateTitle',
    //   title,
    // });
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler(
          'updateTitle',
          {
            title,
          },
          (responseData) => {
            resolve(responseData);
            console.log('JS received response:', responseData);
          }
        );
      });
      // Jockey.send("getNetworkInfo", {}, (payload) => {
      //     // console.log('payload of getNetworkInfo: ');
      //     // console.log(payload);
      //     if (payload && payload.active) {
      //         resolve(payload);
      //     } else {
      //         reject('Not supported');
      //     }
      // });
    });
  },
  updateLogo() {
    //     a) 更新标题
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'updateTitle',
    //   title,
    // });
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('updateLogo', {}, (responseData) => {
          resolve(responseData);
          console.log('JS received response:', responseData);
        });
      });
      // Jockey.send("getNetworkInfo", {}, (payload) => {
      //     // console.log('payload of getNetworkInfo: ');
      //     // console.log(payload);
      //     if (payload && payload.active) {
      //         resolve(payload);
      //     } else {
      //         reject('Not supported');
      //     }
      // });
    });
  },
  showBackAndClose() {
    //     a) 显示关闭按钮 和 左上角返回按钮
    this.showRightClose(true);
    this.showLeftBack();
  },
  showRightClose(flag) {
    //    b) 显示关闭按钮
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler(
          'showRightClose',
          {
            flag,
          },
          (responseData) => {
            console.log('JS received response:', responseData);
            resolve(responseData);
          }
        );
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'showRightClose',
    // });
  },
  showLeftMenu(flag) {
    // c) 显示左上角菜单
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showLeftMenu', { flag }, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });

    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'showLeftMenu',
    // });
  },
  showLeftBack(flag) {
    // d) 显示左上角返回按钮
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showLeftBack', { flag }, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'showLeftBack',
    // });
  },
  showOpenAccountEntry(data) {
    // e) 保存数据
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showOpenAccountEntry', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'setUserData',
    //   data, // data 为 {}
    // });
  },
  refreshOpenAccountStatus(data) {
    // 开户退出刷新页面
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('refreshOpenAccountStatus', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
  },
  getCookie(data) {
    // e) 保存数据
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getCookie', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'setUserData',
    //   data, // data 为 {}
    // });
  },
  setUserData(data) {
    // e) 保存数据
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('setUserData', data, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'setUserData',
    //   data, // data 为 {}
    // });
  },
  getUserData(callback) {
    // f) 获取用户数据
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getUserData', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'getUserData',
    //   callback,
    // });
    // callback("已保存用户数据JSON，或者是一个空的JSON对象字符串")
  },
  getUserInfo(callback) {
    // g)  获取用户uid
    // this.updateTitle('调用了getUserID');
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getUserInfo', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'getUserID',
    //   callback,
    // });
    // callback('{"userID": 10001}')
  },

  showLogin(params) {
    // g)  跳转到login
    // this.updateTitle('调用了showLogin');
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showLogin', params, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
  },
  getUserId(callback) {
    // g)  获取用户uid
    // this.updateTitle('调用了getUserID');
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('getUserId', {}, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
    // window.webkit.messageHandlers.AQUMON.postMessage({
    //   type: 'getUserID',
    //   callback,
    // });
    // callback('{"userID": 10001}')
  },
  // ios端创建一个新的浏览器加载url，没有tab的
  showWebView(url) {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showWebView', { url }, (responseData) => {
          console.log('JS received response:', responseData);
          resolve(responseData);
        });
      });
    });
  },
  // 用于从二级页面跳转到一级页面的方法
  showOpenAccountHTML5() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showOpenAccountHTML5', {}, (responseData) => {
          console.log(
            'showOpenAccountHTML5 JS received response:',
            responseData
          );
          resolve(responseData);
        });
      });
    });
  },
  // 关闭webview
  closeWebView() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('closeWebViewContainer', {}, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
  //h5页面用户行为事件追踪
  trackEvent(event) {
    return new Promise((resolve, reject) => {
      //国内加载不了GA
      let sourceName = sessionStorage.getItem('qd');
      let sourceNameList = [
        'Planto',
        'Facebook',
        'DaWokGum',
        'LinkedIn',
        'Optimise',
        'Mailchimp',
      ];
      if (window.ga && sourceNameList.indexOf(sourceName) > -1) {
        ga('myTracker.send', {
          hitType: 'event',
          eventCategory: 'openAcc',
          eventAction:
            localStorage.getItem('uid') +
            '_' +
            'from' +
            '_' +
            sourceName +
            '_' +
            'Reach',
          eventLabel: event.event,
        });
      }
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('trackEvent', event, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
  // 忘记密码
  showForgetTradePasswordPage() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler(
          'showForgetTradePasswordPage',
          {},
          (responseData) => {
            console.log(
              'showForgetTradePasswordPage JS received response:',
              responseData
            );
            resolve(responseData);
          }
        );
      });
    });
  },
  // 打开原生页面
  showNativePage(params) {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('showNativePage', params, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
  isLogin() {
    return new Promise((resolve, reject) => {
      setupWebViewJavascriptBridge((bridge) => {
        bridge.callHandler('isLogin', {}, (responseData) => {
          resolve(responseData);
        });
      });
    });
  },
};
