/**
 * @description: mpaas点击埋点方法封装
 * @param {string} eventId - 事件ID
 * @param {object} options - 选项配置，包含 bizType、ext
 * @return {undefined}
 */
function Click(eventId, trackerOptions = {}) {
  const options = trackerOptions;
  options.ext = {
    environment: process.env.GATSBY_ENV === 'live' ? 'production' : 'test', // 环境变量，区分测试或者生产
    uid: localStorage.getItem('uid'), // uid，必填
    event_type: 'Button',
    action_type: 'Click',
    ...trackerOptions.ext,
  };

  Tracker.click(eventId, options);
}

/**
 * @description: mpaas访问埋点方法
 * @param {string} eventId - 事件ID
 * @param {object} options - 选项配置，包含 bizType、ext
 * @return {undefined}
 */
function View(eventId, trackerOptions = {}) {
  const options = trackerOptions;
  options.ext = {
    environment: process.env.GATSBY_ENV === 'live' ? 'production' : 'test', // 环境变量，区分测试或者生产
    uid: localStorage.getItem('uid'), // uid，必填
    event_type: 'Page',
    action_type: 'View',
    ...trackerOptions.ext,
  };
  Tracker.expo(eventId, options);
}

const mpaasTracker = {
  Click,
  View,
};

export default mpaasTracker;
