/* eslint-disable */
import nativeBridge from './nativeBridge';
import mockBridge from './mockBridge';
import { func } from 'prop-types';

let app = mockBridge;
const appInfo =
  typeof navigator !== 'undefined' &&
  /AQUMON-(IOS|ANDROID2)-APP-([\d.]+)/i.exec(navigator.userAgent.toUpperCase());
let product = {};
if (appInfo) {
  product = {
    system: appInfo[1],
    version: appInfo[2],
    userAgent: appInfo[0],
  };
  app = nativeBridge;
  app.appInfo = product;
} else {
}
console.log('app information', product);

export default app;
